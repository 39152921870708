import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";

@Module
export default class SurveyModule extends VuexModule {
  log = {};

  get currentSurvey() {
    return this.log;
  }

  @Action
  ["setCurrSurvey"](data) {
    this.context.commit("setCurrSurveyData", data);
  }

  @Mutation
  ["setCurrSurveyData"](payload) {
    this.log = payload;
  }
}
