import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import AuthService from "./AuthService";
import store from "@/store/";
import { Actions } from "@/store/enums/StoreEnums";
import router from "@/router";
import { useToast } from "vue-toastification";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  public static initHeaders = false;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        // Do something with response error
        if (error.response.status === 401 || error.response.status === 403) {
          console.log("unauthorized, logging out ...");
          store.dispatch(Actions.LOGOUT).then(() => {
            location.href = "/sign-in";
          });
        }
        return Promise.reject(error.response);
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(
    baseUrl: undefined | string = undefined,
    override = false
  ): void {
    if (ApiService.initHeaders && !override) {
      return;
    }

    ApiService.initHeaders = true;
    if (baseUrl) {
      ApiService.vueInstance.axios.defaults.baseURL = baseUrl;
    } else if (store.getters.currentSettings.apiUrl !== undefined) {
      ApiService.vueInstance.axios.defaults.baseURL =
        store.getters.currentSettings.apiUrl;
    } else if (window["__PLNT_CONFIG__"]?.AUDIT_API_URL !== undefined) {
      ApiService.vueInstance.axios.defaults.baseURL =
        window["__PLNT_CONFIG__"]?.AUDIT_API_URL.split("/static")[0];
    }
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${AuthService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";

    const toast = useToast();
    ApiService.vueInstance.axios.interceptors.request.use((config) => {
      if (
        config.headers?.common?.Authorization &&
        localStorage.getItem("aTok") != null
      ) {
        config.headers.common.Authorization = localStorage.getItem("aTok");
      }

      return config;
    });
    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        if (response.headers?.atok) {
          localStorage.setItem("aTok", response.headers?.atok);
        }
        return response;
      },
      (error) => {
        toast.error(error?.data?.error);
        return Promise.reject(error);
      }
    );
  }

  public static setToken(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${AuthService.getToken()}`;
  }

  public static setDomain(domainUrl): void {
    ApiService.vueInstance.axios.defaults.headers.common["Domain"] = domainUrl;
  }

  public static getDomain(): void {
    return ApiService.vueInstance.axios.defaults.headers.common["Domain"];
  }

  public static setSegment(segment): void {
    if (segment == "") {
      delete ApiService.vueInstance.axios.defaults.headers.common["Segment"];
    } else {
      ApiService.vueInstance.axios.defaults.headers.common["Segment"] = segment;
    }
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    if (slug == "") {
      return ApiService.vueInstance.axios.get(`${resource}`);
    }
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
