import ApiService from "@/core/services/ApiService";
import AuthService from "@/core/services/AuthService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

export interface User {
  accountId: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!AuthService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    // return AuthService.auth.robustAccount;
    return this.user;
  }

  get isSystemAdmin() {
    const defaultDomain =
      store.getters.currentSettings.planetApp || "web.ourplanet.app";

    let adminUsers = ["amit-dar", "ilan025", "meirav039"];
    if (
      store.getters.currentSettings.adminUsers &&
      store.getters.currentSettings.adminUsers.length > 0
    ) {
      adminUsers = store.getters.currentSettings.adminUsers;
    }

    return (
      adminUsers.indexOf(this.user.accountId) > -1 &&
      this.context.rootState.EnvModule.domain.url == defaultDomain
    );
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    //import AuthService from "@/core/services/AuthService";
    //debugger;
    //return true;
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    //JwtService.saveToken(user.api_token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    AuthService.logout();
    // JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](data) {
    this.context.commit(Mutations.SET_AUTH, data);
    // return ApiService.post("login", credentials)
    //   .then(({ data }) => {
    //     this.context.commit(Mutations.SET_AUTH, data);
    //   })
    //   .catch(({ response }) => {
    //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
    //   });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
    if (AuthService.getToken()) {
      await ApiService.get("auth", "verify-token").catch(() => {
        this.context.commit(Mutations.PURGE_AUTH);
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
