import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";

@Module
export default class ReportModule extends VuexModule {
  report = {};

  get currentReport() {
    return this.report;
  }

  @Action
  ["setCurrReport"](data) {
    this.context.commit("setCurrReportData", data);
  }

  @Mutation
  ["setCurrReportData"](payload) {
    this.report = payload;
  }
}
