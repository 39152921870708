import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";

@Module
export default class LogModule extends VuexModule {
  log = {};

  get currentLog() {
    return this.log;
  }

  @Action
  ["setCurrLog"](data) {
    this.context.commit("setCurrLogData", data);
  }

  @Mutation
  ["setCurrLogData"](payload) {
    this.log = payload;
  }
}
