import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import AuthService from "@/core/services/AuthService";
import ApiService from "@/core/services/ApiService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/log",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/log",
        name: "log",
        component: () => import("@/views/Log.vue"),
        meta: {
          pageTitle: "Activities log",
          breadcrumbs: ["Activities log"],
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/components/page-layouts/Users.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: ["Users"],
        },
        children: [
          {
            path: "list",
            name: "users-list",
            component: () => import("@/views/users/Users.vue"),
            meta: {
              pageTitle: "Users list",
              breadcrumbs: ["Users list"],
            },
          },
          {
            path: "user-blocked",
            name: "users-user-blocked",
            component: () => import("@/views/users/UserBlocked.vue"),
            meta: {
              pageTitle: "Blocked users",
              breadcrumbs: ["Blocked users"],
            },
          },
          {
            path: "admin-blocked",
            name: "users-admin-blocked",
            component: () => import("@/views/users/AdminBlocked.vue"),
            meta: {
              pageTitle: "Blocked users",
              breadcrumbs: ["Blocked users"],
            },
          },
        ],
      },
      {
        path: "/pods",
        name: "pods",
        component: () => import("@/components/page-layouts/Pods.vue"),
        meta: {
          pageTitle: "Pods",
          breadcrumbs: ["Pods"],
        },
        children: [
          {
            path: "list",
            name: "pods-list",
            component: () => import("@/views/pods/Pods.vue"),
            meta: {
              pageTitle: "Pods list",
              breadcrumbs: ["Pods list"],
            },
          },
          {
            path: "user-blocked",
            name: "pods-user-blocked",
            component: () => import("@/views/pods/UserBlocked.vue"),
            meta: {
              pageTitle: "Blocked pods",
              breadcrumbs: ["Blocked pods"],
            },
          },
          {
            path: "admin-blocked",
            name: "pods-admin-blocked",
            component: () => import("@/views/pods/AdminBlocked.vue"),
            meta: {
              pageTitle: "Blocked pods",
              breadcrumbs: ["Blocked pods"],
            },
          },
          {
            path: "messages",
            name: "pods-messages",
            component: () => import("@/views/pods/Messages.vue"),
            meta: {
              pageTitle: "Pods messages",
              breadcrumbs: ["Pods messages"],
            },
          },
          {
            path: "invitations",
            name: "pods-invitations",
            component: () => import("@/views/pods/Invitations.vue"),
            meta: {
              pageTitle: "Pods invitations",
              breadcrumbs: ["Pods invitations"],
            },
          },
        ],
      },
      {
        path: "/reachouts",
        name: "reachouts",
        component: () => import("@/components/page-layouts/Reachouts.vue"),
        meta: {
          pageTitle: "Reachouts",
          breadcrumbs: ["Reachouts"],
        },
        children: [
          {
            path: "list",
            name: "reachouts-list",
            component: () => import("@/views/reachouts/Reachouts.vue"),
            meta: {
              pageTitle: "Reachouts list",
              breadcrumbs: ["Reachouts list"],
            },
          },
          {
            path: "user-blocked",
            name: "reachouts-user-blocked",
            component: () => import("@/views/reachouts/UserBlocked.vue"),
            meta: {
              pageTitle: "Blocked reachouts",
              breadcrumbs: ["Blocked Reachouts"],
            },
          },
          {
            path: "admin-blocked",
            name: "reachouts-admin-blocked",
            component: () => import("@/views/reachouts/AdminBlocked.vue"),
            meta: {
              pageTitle: "Blocked reachouts",
              breadcrumbs: ["Blocked Reachouts"],
            },
          },
        ],
      },
      {
        path: "/surveys",
        name: "surveys",
        component: () => import("@/components/page-layouts/Surveys.vue"),
        meta: {
          pageTitle: "Surveys",
          breadcrumbs: ["Surveys"],
        },
        children: [
          {
            path: "list",
            name: "surveys-list",
            component: () => import("@/views/surveys/List.vue"),
            meta: {
              pageTitle: "Surveys list",
              breadcrumbs: ["Surveys list"],
            },
          },
        ],
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/components/page-layouts/Settings.vue"),
        meta: {
          pageTitle: "Settings",
          breadcrumbs: ["Settings"],
        },
        children: [
          {
            path: "permissions",
            name: "settings-permissions",
            component: () => import("@/views/settings/Permissions.vue"),
            meta: {
              pageTitle: "Permissions",
              breadcrumbs: ["Permissions"],
            },
          },
          {
            path: "api-keys",
            name: "settings-apikeys",
            component: () => import("@/views/settings/ApiKeys.vue"),
            meta: {
              pageTitle: "ApiKeys",
              breadcrumbs: ["ApiKeys"],
            },
          },
          {
            path: "surveys",
            name: "settings-surveys",
            component: () => import("@/views/settings/Surveys.vue"),
            meta: {
              pageTitle: "Surveys",
              breadcrumbs: ["Surveys"],
            },
          },
          {
            path: "segments",
            name: "settings-segments",
            component: () => import("@/views/settings/Segments.vue"),
            meta: {
              pageTitle: "Segments",
              breadcrumbs: ["Segments"],
            },
          },
          {
            path: "advanced",
            name: "settings-advanced",
            component: () => import("@/views/settings/Advanced.vue"),
            meta: {
              pageTitle: "Advanced",
              breadcrumbs: ["Advanced"],
            },
          },
        ],
      },
      {
        path: "/reported",
        name: "reported",
        component: () => import("@/views/ReportedUsers.vue"),
        meta: {
          pageTitle: "Reported Users",
          breadcrumbs: ["Reported"],
        },
      },
      {
        path: "/projects",
        name: "projects",
        component: () => import("@/components/page-layouts/Projects.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumbs: ["Projects"],
        },
        children: [
          {
            path: "list",
            name: "projects-list",
            component: () => import("@/views/projects/List.vue"),
            meta: {
              pageTitle: "Projects list",
              breadcrumbs: ["Projects list"],
            },
          },
        ],
      },
      {
        path: "/reports",
        name: "reports",
        component: () => import("@/views/reports/List.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumbs: ["Projects"],
        },
        children: [
          {
            path: "list",
            name: "reports-list",
            component: () => import("@/views/reports/List.vue"),
            meta: {
              pageTitle: "Reports list",
              breadcrumbs: ["Reports list"],
            },
          },
        ],
      },
      {
        path: "/statistics",
        name: "statistics",
        component: () => import("@/components/page-layouts/Statistics.vue"),
        meta: {
          pageTitle: "Statistics",
          breadcrumbs: ["Statistics"],
        },
        children: [
          {
            path: "components",
            name: "statistics-components",
            component: () => import("@/views/statistics/Components.vue"),
            meta: {
              pageTitle: "Components",
              breadcrumbs: ["Components"],
            },
          },
          {
            path: "users-activity",
            name: "statistics-users-activity",
            component: () => import("@/views/statistics/UsersActivity.vue"),
            meta: {
              pageTitle: "Users' Activities",
              breadcrumbs: ["Users' Activities"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/admins",
        name: "admins-sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/s/:segment",
        name: "segment-sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    meta: {
      pageTitle: "Sign In",
    },
    component: () =>
      import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let currConfig = null;
router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  async function getConfig(to) {
    if (currConfig == null) {
      let site = localStorage.getItem("site") as any;
      if (site != null) {
        site = JSON.parse(site);
      }
      const urlSearchParams = new URLSearchParams(window.location.search);
      const queryParams = Object.fromEntries(urlSearchParams.entries());
      const appConfig = queryParams?.c
        ? `&c=${queryParams.c}`
        : site?.config
        ? `&c=${site?.config}`
        : "";

      const baseUrl =
        site?.config.indexOf("http") > -1
          ? site?.config
          : "https://" + site?.config;

      const configServer =
        store.getters.currentSettings.configServer ||
        "https://config.robust-sw.com";
      const isLocalDeployment = site?.local;
      const url = isLocalDeployment
        ? baseUrl + "/rfiles/spa/rcnapps/SyncUp/config.json"
        : configServer + `?v=1&m=spa&b=1` + `${appConfig}`;

      const result = await fetch(url, {
        method: "POST",
      });
      const config = await result.json();

      if (isLocalDeployment) {
        config["API_BASE_URL"] = baseUrl + config["API_BASE_URL"];
        config["AUTH_SCRIPT_URL"] = baseUrl + config["AUTH_SCRIPT_URL"];
      }

      window["__PLNT_CONFIG__"] = config;
      currConfig = config;
    }

    ApiService.setHeader();

    let site = localStorage.getItem("site") as any;
    if (site != null) {
      site = JSON.parse(site);
      ApiService.setDomain(site?.name);
    }

    // before page access check if page requires authentication
    if (to.meta.middleware == "auth") {
      if (
        localStorage.getItem("LocalRobustAuthCredentials") == null &&
        to.name != "sign-in"
      ) {
        next("/sign-in");
      } else {
        AuthService.init();
        await AuthService.initMyAuth();

        if (store.getters.isUserAuthenticated) {
          ApiService.setToken();
          next();
        } else {
          next("/sign-in");
        }
      }
    } else {
      next();
    }

    // Scroll page to top on every route change
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  getConfig(to);
});

export default router;
