import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import VueClipboard from "vue3-clipboard";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { useConfig } from "@/core/services/ConfigService";

import "@/core/plugins/prismjs";
const app = createApp(App);

(async () => {
  const settings = await useConfig();
  store.dispatch("setSettings", settings.config.value);

  app.use(store);
  app.use(router);
  app.use(ElementPlus);
  app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  });
  app.use(Toast, {
    position: POSITION.TOP_CENTER,
  });

  ApiService.init(app);
  // AuthService.init(app);

  initApexCharts(app);
  initInlineSvg(app);
  initVeeValidate();

  app.use(i18n);

  app.mount("#app");
})();
