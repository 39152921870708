import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import LogModule from "@/store/modules/LogModule";
import SurveyModule from "@/store/modules/SurveyModule";
import ReportModule from "@/store/modules/ReportModule";
import ProjectsModule from "@/store/modules/ProjectsModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import EnvModule from "@/store/modules/EnvModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    LogModule,
    SurveyModule,
    ReportModule,
    ProjectsModule,
    BodyModule,
    ConfigModule,
    EnvModule,
    ThemeModeModule,
  },
});

export default store;
