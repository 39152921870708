import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";

@Module
export default class ProjectsModule extends VuexModule {
  projects = [];
  tree = {};

  get currentProjects() {
    return this.projects;
  }

  get currentTree() {
    return this.tree;
  }

  @Action
  ["setProjects"](data) {
    this.context.commit("setProjectsData", data);
  }

  @Action
  ["setProjectsTree"](data) {
    this.context.commit("setProjectsTreeData", data);
  }

  @Mutation
  ["setProjectsData"](payload) {
    this.projects = payload;
  }

  @Mutation
  ["setProjectsTreeData"](payload) {
    this.tree = payload;
  }
}
