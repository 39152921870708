import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

@Module
export default class EnvModule extends VuexModule {
  domain = { name: "", url: "", permission: "", segments: [], local: false };
  segment = { Name: "" };
  settings = {};

  get currentSettings() {
    return this.settings;
  }

  get currentDomain() {
    return this.domain;
  }

  get publicUrl() {
    if (this.domain.local) {
      return this.domain.url + "/rfiles/spa/rcnapps/SyncUp";
    }

    return this.domain.url;
  }

  get currentSegment() {
    return this.segment;
  }

  get isAdmin() {
    if (this.domain.segments) {
      return this.domain.segments.find((obj: any) => obj.role == "admin");
    }
    return false;
  }

  get isSegmentUser() {
    return this.domain.segments.find(
      (obj: any) =>
        ["segment-admin", "segment-manager", "segment-view"].indexOf(obj.role) >
          -1 && obj.Name == this.segment?.Name
    );
  }

  get isSegmentAdmin() {
    return this.domain.segments.find(
      (obj: any) =>
        obj.role == "segment-admin" && obj.Name == this.segment?.Name
    );
  }

  get isDomainAdmin() {
    if (this.domain.segments) {
      return this.domain.segments.find((obj: any) => obj.role == "admin");
    }
    return false;
  }

  get canManage() {
    if (this.domain.segments) {
      return this.domain.segments.find(
        (obj: any) => obj.role == "manager" || obj.role == "admin"
      );
    }
    return false;
  }

  @Action
  [Actions.SET_SETTINGS](data) {
    this.context.commit(Mutations.SET_SETTINGS, data);
  }
  @Action
  [Actions.SET_DOMAIN](data) {
    this.context.commit(Mutations.SET_DOMAIN, data);
  }
  @Action
  [Actions.SET_SEGMENT](data) {
    this.context.commit(Mutations.SET_SEGMENT, data);
  }

  @Mutation
  [Mutations.SET_SETTINGS](payload) {
    this.settings = payload;
  }
  @Mutation
  [Mutations.SET_DOMAIN](payload) {
    this.domain = payload;
  }
  @Mutation
  [Mutations.SET_SEGMENT](payload) {
    this.segment = payload;
  }
}
