import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store/";

/**
 * @description service to call HTTP request via Axios
 */
class AuthService {
  static ID_TOKEN_KEY = "LocalRobustAuthCredentials" as string;

  /**
   * @description property to share vue instance
   */
  //   public static vueInstance: App;

  public static auth;

  public static initFlag = false;

  public static loggedInFlag = false;

  /**
   * @description initialize vue axios
   */
  public static init() {
    if (AuthService.initFlag) {
      return;
    }
    // AuthService.vueInstance = app;

    AuthService.initFlag = true;

    const scriptTag = document.createElement("script");
    scriptTag.text = `
            window['loadAuthScript'] = () =>
            import("${window["__PLNT_CONFIG__"].AUTH_SCRIPT_URL}").then((module) => {
              window['authScript'] = module;
            }).catch(e => {
              console.error(e);
            });
            `;

    document.head.appendChild(scriptTag);
  }

  public static async initMyAuth(logo?, title?) {
    if (AuthService.loggedInFlag) {
      return;
    }

    const authConfig = {
      baseUrl: window["__PLNT_CONFIG__"].API_BASE_URL,
      providerIds: ["common", "google", "apple", "unpw"],
      udacId: "wdirs",
      storage: "auto",
      common: {
        logoUrl: logo ? logo : "icons/Planet-logo.svg",
        screenTitle: title ? title : "",
        title: "Login to Planet", // i18n.t('auth:title'), needs to be responsive
        tenantTitle: "", // i18n.t('auth:description'), needs to be responsive
        providers: ["unpw", "google", "apple"],
        canSignUp: false,
        canClose: false,
        can1_timeCode: true,
      },
    };

    const signUpConfig = {
      signupUrl: window["__PLNT_CONFIG__"].SIGN_UP_URL,
    };

    await window["loadAuthScript"]();
    if (window["authScript"] !== undefined) {
      const { Auth } = window["authScript"];
      const currLoggedIn =
        localStorage.getItem(AuthService.ID_TOKEN_KEY) != null;

      AuthService.auth = await Auth.createAuth(
        // returns Auth class instance
        "/rfiles/common/configs/robust-auth/client-config.json", // points to config in robust server.
        authConfig,
        () => {
          const loggedIn = AuthService.auth?.robustAccount || null;
          // On account change
          if (
            currLoggedIn == false &&
            loggedIn != null &&
            loggedIn.tenantId == "CAS"
          ) {
            window.location.reload();
          }
        },
        signUpConfig
      );

      await AuthService.auth.init();
      await AuthService.auth.commonSignIn("CAS", false);
      store.dispatch(Actions.LOGIN, AuthService.auth.robustAccount);
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "noscroll");

      AuthService.loggedInFlag = true;
    }
  }

  public static async logout() {
    if (AuthService.auth) {
      AuthService.loggedInFlag = false;
      await AuthService.auth.signOut();
      localStorage.removeItem(AuthService.ID_TOKEN_KEY);
    }
  }

  public static getToken = (): string | null => {
    if (AuthService.auth) {
      return AuthService.auth.token;
    }

    return "";
  };
}

export default AuthService;
