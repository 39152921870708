import { ref } from "vue";

export async function useConfig() {
  const config = ref({});

  try {
    const response = await fetch("/config/settings.json");

    const body = await response.text();
    try {
      const json = JSON.parse(body);
      config.value = json;
    } catch (e) {
      // do nothing
    }
  } catch (error) {
    // do nothing
  }

  return {
    config,
  };
}
